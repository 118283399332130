import {
  currencyFilter,
  formatStringDateToISO,
  moneyToNumber,
  removeSpecialsCharacters,
} from "@/utils/converters";
import moment from "moment";

export const required = (value: string) => {
  return !!value || "Esse campo é obrigatório";
};

export const validationCPF = (value: string) => {
  if (!value) return false;
  value = removeSpecialsCharacters(value);
  if (value === "") return false;

  if (
    value.length !== 11 ||
    value === "00000000000" ||
    value === "11111111111" ||
    value === "22222222222" ||
    value === "33333333333" ||
    value === "44444444444" ||
    value === "55555555555" ||
    value === "66666666666" ||
    value === "77777777777" ||
    value === "88888888888" ||
    value === "99999999999"
  ) {
    return "Esse CPF não é válido";
  }

  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(value.charAt(i)) * (10 - i);
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(value.charAt(9))) return "Esse CPF não é válido";

  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(value.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  return rev === parseInt(value.charAt(10)) || "Esse CPF não é válido";
};

export const loanValueMin = (value: string, { minValue }: { minValue: number }) => {
  if (!value) return "Insira um valor válido";

  const newValue = Number(moneyToNumber(value));
  if (isNaN(newValue)) {
    return "Insira um valor válido";
  }
  if (newValue < minValue) return `Insira um valor maior que R$ ${300},00`;
  return true;
};

export const loanValueMax = (value: string, maxValue = 60000) => {
  if (!value) return "Insira um valor válido";

  const newValue = Number(moneyToNumber(value));
  if (isNaN(newValue)) {
    return "Insira um valor válido";
  }
  if (newValue > maxValue && !isNaN(newValue)) {
    return `Insira um valor menor que ${currencyFilter(String(maxValue))}`;
  }
  return true;
};

export const validateLoanValue = (value: string, min: number, max: number) => {
  const convertedValue = Number(moneyToNumber(value));
  if (convertedValue >= min && convertedValue <= max) return true;
  return `O valor deve estar entre ${currencyFilter(min)} e ${currencyFilter(max)}`;
};

export const validateUniqueValues = (value: string, { values }: { values: [] }) => {
  if (!values) return "O array não pode ser nulo ou undefined";
  for (const outValue of values) {
    const filteredItem = values.filter((item) => String(item) === String(outValue));

    if (filteredItem.length > 1) return "As referências não podem ser iguais";
  }
  return true;
};

export const validateUniqueValue = (value: string, { uniqueValue }: { uniqueValue: string }) => {
  if (!value) return "Insira um valor válido";
  if (removeSpecialsCharacters(value) === uniqueValue) {
    return "Não pode ser o seu.";
  }
  return true;
};

export const minimumCharacters = (value: string, { min }: { min: number }) => {
  if (!value) return "Insira um valor válido";
  return (
    removeSpecialsCharacters(value).length >= min ||
    `O campo {_field_} deve conter pelo menos ${min} caracteres`
  );
};

export const validateDate = (date: string) => {
  if (!date) return "Insira um valor válido";
  const formattedDate = date.split("/").reverse().join("-");

  const minDate = "1900-01-01";

  const todayDate = new Date();

  const seventeenYearsAgo = todayDate.setFullYear(todayDate.getFullYear() - 17);

  if (
    Date.parse(formattedDate) >= Date.parse(minDate) &&
    Date.parse(formattedDate) <= seventeenYearsAgo
  ) {
    return true;
  }
  return "O campo {_field_} deve conter uma data válida";
};

export const hasEmptySpaces = (value: string) => {
  if (["", undefined].includes(value)) {
    throw new TypeError("O valor deve ser uma string válida");
  }
  if (value.includes(" ")) {
    return "O campo {_field_} não pode conter espaços vazios";
  }
  return true;
};

export const minimumValue = (value: string, { minimumValue }: { minimumValue: number }) => {
  const formattedValue = moneyToNumber(value);

  return (
    Number(formattedValue) >= minimumValue ||
    `Insira um valor maior que ${currencyFilter(minimumValue)}`
  );
};

export const maximumValue = (
  value: string,
  { maximumValue, allowZero = true }: { maximumValue: number; allowZero?: boolean }
) => {
  const formattedValue = moneyToNumber(value);

  if (allowZero && maximumValue === 0) {
    return true;
  }

  return (
    Number(formattedValue) <= maximumValue ||
    `Insira um valor menor que ${currencyFilter(maximumValue)}`
  );
};

export const dateIntervalValidate = (
  value: string,
  { minDate, maxDate }: { minDate: string; maxDate: string }
) => {
  if (!value) return "Insira uma data válida";

  const date = new Date(formatStringDateToISO(value));
  const minDateTime = new Date(formatStringDateToISO(minDate));
  const maxDateTime = new Date(formatStringDateToISO(maxDate));

  const isValidDate = moment(value, "DD/MM/YYYY").isValid();

  if (!isValidDate) return `A data ${value} não é uma data válida`;

  if (date >= minDateTime && date <= maxDateTime) return true;

  return `Insira uma data entre o dia ${minDate} e ${maxDate}`;
};
